<template>
  <div class="borrowingForm">
    <div class="borrowingForm-box">
      <div class="item">
        <div class="item-l">家友姓名：</div>
        <div class="item-r">
          <v-input v-model="form.familyName"></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">房号：</div>
        <div class="item-r"><v-input v-model="form.houseNo"></v-input></div>
      </div>
      <div class="item">
        <div class="item-l">证件号：</div>
        <div class="item-r">
          <v-input type="number" v-model="form.identityNumber"></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">联系电话：</div>
        <div class="item-r">
          <v-input
            type="bumber"
            :maxlength="11"
            v-model="form.mobile"
            @blur="blurPhone"
          ></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">借物名称：</div>
        <div class="item-r">
          <span>{{ oData.itemName }}</span>
        </div>
      </div>
      <div class="item">
        <div class="item-l">借物数量：</div>
        <div class="item-r">
          <div class="num">
            <div class="jian" @click="jian">
              <img src="./img/jian.png" alt="" />
            </div>
            <div class="txt">{{ form.borrowNums }}</div>
            <div class="add" @click="add">
              <img src="./img/add.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="item" @click="seclectDate">
        <div class="item-l">归还日期：</div>
        <div class="item-r">
          <div class="item-r-box">
            <div class="txt">{{ form.returnDate }}</div>
            <div class="img"><img src="./img/date.png" alt="" /></div>
          </div>
        </div>
      </div>
      <div class="submit" @click="submit">提交</div>
    </div>
    <v-dateTimePicker
      type="date"
      :value="form.returnDate"
      :minDate="new Date(2023, 0, 1)"
      :valueShow.sync="isShowNurseryDate"
      :isAuto="true"
      @confirmPicker="confirmNurseryDate"
    ></v-dateTimePicker>
  </div>
</template>

<script>
import { getBorrowItemsByIdUrl, saveOrUpdateBorrowRecordsUrl } from "./api.js";
import { mobileRegular } from "@/utils/regular";
var moment = require("moment");
export default {
  name: "borrowingForm",
  data() {
    return {
      id: "",
      oData: {},
      isShowNurseryDate: false,
      form: {
        familyName: "",
        houseNo: "",
        identityNumber: "",
        mobile: "",
        returnDate: "",
        borrowNums: 0,
      },
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
    // gloabalCount("", 20, 1);
  },
  methods: {
    async submit() {
      if (!this.form.familyName) {
        this.$toast("请填写家友姓名！");
        return;
      }
      if (!this.form.houseNo) {
        this.$toast("请填写房号！");
        return;
      }
      if (!this.form.identityNumber) {
        this.$toast("请填写证件号！");
        return;
      }
      if (!this.form.borrowNums) {
        this.$toast("请填写数量！");
        return;
      }
      if (!this.form.returnDate) {
        this.$toast("请选择归还日期！");
        return;
      }
      let params = {
        borrowNums: this.form.borrowNums,
        familyName: this.form.familyName,
        houseNo: this.form.houseNo,
        identityNumber: this.form.identityNumber,
        insertUser: this.userId,
        itemId: this.id,
        itemName: this.oData.itemName,
        itemType: this.oData.itemType,
        mobile: this.form.mobile,
        returnDate: this.form.returnDate + " " + "00:00:00",
      };
      let res = await this.$axios.post(
        `${saveOrUpdateBorrowRecordsUrl}`,
        params
      );
      if (res.code === 200) {
        this.$toast("操作成功");
        this.$router.replace({
          name: "borrowingStatus",
        });
      }
    },
    add() {
      this.form.borrowNums++;
    },
    jian() {
      if (this.form.borrowNums > 0) {
        this.form.borrowNums--;
      }
    },
    confirmNurseryDate(value) {
      this.form.returnDate = moment(value).format("YYYY-MM-DD");
    },
    seclectDate() {
      this.isShowNurseryDate = true;
    },
    blurPhone() {
      let regExp = mobileRegular;
      if (!regExp.test(this.form.mobile)) {
        this.$toast("请正确填写手机号");
        this.form.mobile = "";
      } else {
      }
    },
    async getDetail() {
      let params = { id: this.id };
      let res = await this.$axios.get(`${getBorrowItemsByIdUrl}`, { params });
      if (res.code === 200) {
        this.oData = res.data;
      }
    },
    async toDetail(item) {
      let params = {
        id: item.id,
      };
      this.$router.push({
        name: "borrowingDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.borrowingForm {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .borrowingForm-box {
    padding: 18px 42px;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 28px 0;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      .item-l {
        width: 168px;
        font-size: 32px;
        font-weight: 700;
        color: #333333;
        line-height: 44px;
      }
      .item-r {
        flex: 1;
        text-align: right;
        .num {
          display: flex;
          justify-content: right;
          align-items: center;
          .add,
          .jian {
            width: 42px;
            height: 44px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .txt {
            margin: 0 50px;
            font-size: 28px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
          }
        }
        .item-r-box {
          display: flex;
          justify-content: right;
          align-items: center;
          .txt {
            font-size: 28px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
          }
          .img {
            width: 32px;
            height: 32px;
            margin-left: 16px;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        span {
          font-size: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 30px;
        }
        /deep/ .van-cell {
          height: 44px;
          padding: 0;
          .van-field__control {
            text-align: right;
          }
        }
      }
    }
    .submit {
      width: 530px;
      position: fixed;
      bottom: 102px;
      left: 0;
      right: 0;
      margin: auto;
      height: 70px;
      background: #8a95a6;
      border-radius: 18px;
      font-size: 28px;
      text-align: center;
      font-weight: 400;
      color: #ffffff;
      line-height: 70px;
    }
  }
}
</style>
<style lang="less">
.borrowingForm {
}
</style>
