//借物管理列表
const findBorrowItemsPageUrl = `/gateway/hc-external/borrowItems/findBorrowItemsPage`;
//保存借物
const saveOrUpdateBorrowItemsUrl = `/gateway/hc-external/borrowItems/saveOrUpdateBorrowItems`;
//删除
const removeBorrowItemsByIdUrl = `/gateway/hc-external/borrowItems/removeBorrowItemsById`;

//类型
const getItemTypeCodeDictUrl = `/gateway/hc-external/borrowItems/getItemTypeCodeDict`;
//借物详情
const getBorrowItemsByIdUrl = `/gateway/hc-external/borrowItems/getBorrowItemsById`;

//删除
const changeStatusUrl = `/gateway/hc-external/borrowItems/changeStatus`;
//借物登记
const findBorrowRecordsPageUrl = `/gateway/hc-external/borrowRecords/findBorrowRecordsPage`;
//借物登记详情
const getBorrowRecordsByIdUrl = `/gateway/hc-external/borrowRecords/getBorrowRecordsById`;
//登记借物
const saveOrUpdateBorrowRecordsUrl = `/gateway/hc-external/borrowRecords/saveOrUpdateBorrowRecords`;

export {
  saveOrUpdateBorrowRecordsUrl,
  getBorrowRecordsByIdUrl,
  findBorrowRecordsPageUrl,
  changeStatusUrl,
  getBorrowItemsByIdUrl,
  findBorrowItemsPageUrl,
  saveOrUpdateBorrowItemsUrl,
  removeBorrowItemsByIdUrl,
  getItemTypeCodeDictUrl,
};
